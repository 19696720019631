import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Menu = props => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/">
            Inicio
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/bazar">
            Linea Bazar
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/infantil">
            Linea Infantil
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/fama">
            Somos Famosos!
          </Link>
        </li>
      </ul>
    </div>
    <a className="close" onClick={props.onToggleMenu} href="javascript:;">
      Close
    </a>
  </nav>
);

Menu.propTypes = {
  onToggleMenu: PropTypes.func,
};

export default Menu;
