import React from "react";

const Contact = props => (
  <section id="contact">
    <div className="inner">
      <section>
        <form
          name="contactosweb"
          method="post"
          action="/success?no-cache=1"
          data-netlify="true"
          data-netlify-honeypot="bot-field">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contactosweb" />
          <div className="field">
            <label htmlFor="name">Nombre</label>
            <input type="text" name="nombre" id="name" />
          </div>
          <div className="field half first">
            <label htmlFor="name">Teléfono</label>
            <input type="text" name="telefono" id="telefono" />
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email" />
          </div>
          <div className="field">
            <label htmlFor="message">Mensaje</label>
            <textarea name="mensaje" id="message" rows="6" />
          </div>
          <div className="field">
            <div data-netlify-recaptcha="true" />
          </div>
          <ul className="actions">
            <li>
              <input type="submit" value="Enviar" className="special" />
            </li>
          </ul>
        </form>
      </section>
      <section className="split">
        <section>
          <div className="contact-method">
            <span className="icon alt fa-envelope" />
            <h3>Email</h3>
            <a href="#">info@plasticoslucero.com.ar</a>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-phone" />
            <h3>Teléfono</h3>
            <span>+54 11 4581-6542</span>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-home" />
            <h3>Dirección</h3>
            <span>
              {" "}
              Dr. Luis Belaustegui 1158
              <br />
              CABA, Buenos Aires
              <br />
              Argentina
              <br />
              CP: C1416CXN
            </span>
          </div>
        </section>
      </section>
    </div>
  </section>
);

export default Contact;
